/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/*
	T H E M E
*/

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;700&display=swap');


// CONTAINER
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

// BACKGROUND
.mat-app-background {
	background-color: #121212;
}

.hide-tabs-header {
  > .mat-tab-header {
    display: none;
  }
}

// ANGULAR MATERIAL COMPONENTS
mat-slide-toggle .mat-slide-toggle-content {
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 16px;
	color: rgba(255, 255, 255, 0.6);
}

.slide-toggle-wrapper mat-error {
	font-size: 75%;
	padding-left: 12px;
}

mat-spinner {
	display: block !important;
}

// TYPOGRAPHY
h1 {
	font-family: 'DIN Condensed', 'Open Sans', sans-serif !important;
	font-style: normal;
	font-weight: bold;
	font-size: 56px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #FFFFFF;
	margin-top: 16px !important;
}

h4 {
	font-family: Inter;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 11px;
	line-height: 12px;
	text-transform: uppercase;
	color: #C5C4FF;
	margin-top: 32px !important;
}

p {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 18px !important;
	line-height: 28px !important;
	color: rgba(255, 255, 255, 0.6) !important;
}

.bc-headline {
	margin: 64px 0 !important;
}

// FORM FIELD
mat-form-field {
	display: block !important;
}



// BUTTON
.bc-btn-flat-white {
	width: 100%;
	background-color: #fff !important;
	color: #212067 !important;
	font-family: 'Inter', sans-serif !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 18px !important;
	line-height: 20px !important;
	padding: 18px !important;
}

// CARD
.bc-card {
	position: relative;
	width: 100%;
	height: 500px;
	margin-bottom: 16px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.15);
	box-sizing: border-box !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	
	p {
		color: #fff !important;
		padding: 8px 0 !important;
	}

	.bc-card-image {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 14.35%;

		border-top-left-radius: 16px;
		border-top-right-radius: 16px;

		background-size: cover;
		background-position: center center;
	}

	.bc-card-content {
		position: absolute;
		left: 0%;
		right: 0%;
		bottom: 0%;
		padding: 16px 24px;
		
		.bc-trainer-tags {
			padding: 8px 0 4px 0;
			div {
				display: inline-block;
				justify-content: center;
				align-items: center;
				padding: 4px 8px;

				background: rgba(232, 28, 51, 0.8);
				backdrop-filter: blur(10px);
				margin-right: 8px;
				margin-bottom: 8px;
				/* Note: backdrop-filter has minimal browser support */

				border-radius: 4px;
				span {
					font-family: 'Inter', sans-serif;
					font-style: normal;
					font-weight: 600;
					font-size: 11px;
					line-height: 20px;
					display: flex;
					align-items: center;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					color: rgba(255, 255, 255, 0.75);
				}
			}
		
		}
	}

	.bc-gradient-short {
		position: absolute;
		left: 0%;
		right: 0%;
		top: 31.48%;
		bottom: 0%;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		background: linear-gradient(180deg, rgba(81, 78, 255, 0.004) 0%, rgba(81, 78, 255, 0.1) 26.04%, rgba(81, 78, 255, 0.2) 43.75%, rgba(81, 78, 255, 0.3) 63.02%, rgba(81, 78, 255, 0.4) 83.33%);
	}

	.bc-gradient-long {
		position: absolute;
		left: 0%;
		right: 0%;
		top: 18.63%;
		bottom: 0%;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.25) 26.04%, rgba(0, 0, 0, 0.5) 43.75%, rgba(0, 0, 0, 0.75) 63.02%, #000000 83.33%);
	}

	.bc-trainer-licenses-headline {
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		line-height: 24px;
		text-transform: uppercase;
		color: #FFFFFF;
		margin-bottom: 8px;
	}

	.bc-trainer-license-item {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		color: #C5C4FF;
	}

	hr {
		margin: 28px -24px;
		border: none;
		border-top: 1px solid rgba(197, 196, 255, 0.5);
	}
}